import { forwardRef } from "react";

export const LetterIcon = forwardRef(({ children, ...props }, ref) => {
  if (typeof children !== "string") return null;
  const isSingleChar = children.length === 1;
  return (
    <svg ref={ref} viewBox="0 0 18 13" width="1em" height="1em" {...props}>
      <text
        x={isSingleChar ? 9 : 0}
        y="100%"
        textAnchor={isSingleChar ? "middle" : undefined}
        textLength={isSingleChar ? undefined : "100%"}
        fontSize="19"
        fontWeight="600"
        fill="currentColor"
        lengthAdjust="spacingAndGlyphs"
        style={{ userSelect: "none", fontFamily: "var(--font-sans)" }}
      >
        {children}
      </text>
    </svg>
  );
});
