import * as React from "react";
import { forwardRef } from "react";

const SvgLine = (props, ref) => (
  <svg
    width={18}
    height={2}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 2"
    ref={ref}
    {...props}
  >
    <path fill="#6B7178" d="M0 0h18v2H0z" />
  </svg>
);
const ForwardRef = forwardRef(SvgLine);
export default ForwardRef;
