import * as React from "react";
import { forwardRef } from "react";

const SvgDashedLine = (props, ref) => (
  <svg
    width={18}
    height={2}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 2"
    ref={ref}
    {...props}
  >
    <path fill="#6B7178" d="M0 0h3v2H0zM5 0h3v2H5zM10 0h3v2h-3zM15 0h3v2h-3z" />
  </svg>
);
const ForwardRef = forwardRef(SvgDashedLine);
export default ForwardRef;
